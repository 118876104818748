// packages
import React from 'react';
import ReactDOM from 'react-dom/client';
// packages
import App from './containers/App';
import reportWebVitals from './reportWebVitals';
// statics
import './static/css/tag.css'
import './static/css/id.css'
import './static/css/class.css';
import './static/css/medium.css'
import './static/css/large.css'
import './static/css/extra-large.css'



const rootDom = document.getElementById('root');
const root = ReactDOM.createRoot(rootDom);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
